import React, { useEffect } from "react"
import { Link, graphql } from "gatsby" //highlight-line
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/react"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { device } from "../components/device"
import "swiper/swiper.scss"

SwiperCore.use([Navigation, Pagination, Autoplay])

export default function Home({ data }) {
  const home = data.wpPage.homepage
  const headerImages = data.wpPage.homepage.headerimages
  const spacing = "1.5rem clamp(1.2rem, 4vw, 6rem)"
  const spacingH = "clamp(1.2rem, 4vw, 6rem)"

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Corrigan Gore",
    legalName: "Corrigan Gore Limited",
    url: "https://corrigangore.com/",
    logo:
      "https://res.cloudinary.com/corrigangore/image/upload/v1625576132/corrigan-gore-grey-logo_ihhibg.jpg",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Unit 1 Kingfisher House, Juniper Drive",
      addressLocality: "Battersea Reach",
      addressRegion: "London",
      postalCode: "SW18 1TX",
      addressCountry: "UK",
    },
    telephone: "+44 (0)20 3773 3637",
    email: "info@corrigangore.com",

    sameAs: [
      "https://twitter.com/CorriganGore",
      "https://www.linkedin.com/company/corrigangore",
      "https://www.instagram.com/corrigan_gore/",
    ],
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const intros = gsap.utils.toArray(".fade-intro")
    intros.forEach((intro, i) => {
      const anim = gsap.fromTo(
        intro,
        { autoAlpha: 0, y: 50 },
        { duration: 1.5, autoAlpha: 1, y: 0, ease: "power1.inOut" }
      )
      ScrollTrigger.create({
        trigger: intro,
        animation: anim,
        toggleActions: "play none none none",
      })
    })

    const fades = gsap.utils.toArray(".fade")
    fades.forEach((fade, i) => {
      const anim = gsap.fromTo(
        fade,
        { autoAlpha: 0, y: 50 },
        {
          duration: 1,
          autoAlpha: 1,
          stagger: 0.5,
          y: 0,
          ease: "power1.inOut",
        }
      )
      ScrollTrigger.create({
        trigger: fade,
        animation: anim,
        scrub: true,
        start: "top 75%",
        end: "+=350px",
        toggleActions: "play none play none",
      })
    })
  }, [])

  // if (typeof window === "undefined") return

  // const [size, setSize] = useState({
  //   // x: window.innerWidth,
  //   y: window.innerHeight,
  // })
  // const updateSize = () =>
  //   setSize({
  //     // x: window.innerWidth,
  //     y: window.innerHeight,
  //   })
  // return () => {
  //   window.onresize = (size, updateSize)
  // }

  function colorHeader() {
    const activeSlide = document.querySelector(".swiper-slide-active")
    const app = document.querySelector(".app")
    if (activeSlide.classList.contains("white")) {
      app.classList.add("white")
    } else {
      app.classList.remove("white")
    }
  }

  // useEffect(() => {
  //   // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  //   let vh = window.innerHeight * 0.01
  //   // Then we set the value in the --vh custom property to the root of the document
  //   document.documentElement.style.setProperty("--vh", `${vh}px`)

  //   // We listen to the resize event
  //   window.addEventListener("resize", () => {
  //     // We execute the same script as before
  //     let vh = window.innerHeight * 0.01
  //     document.documentElement.style.setProperty("--vh", `${vh}px`)
  //   })
  // })

  return (
    <Layout>
      <SEO title="Quantity Surveyor" schemaMarkup={schema} />
      <section
        className="headerContainer"
        css={css`
          position: relative;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
        `}
      >
        <Swiper
          slidesPerView={`auto`}
          loop={true}
          initialSlide={0}
          navigation={{
            nextEl: ".swiper-button-next",
            clickable: true,
            renderNextButton: className => {
              return '<span class"' + className + '"></span>"'
            },
          }}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: (index, className) => {
              return (
                '<span class="' + className + '">' + (index + 1) + "</span>"
              )
            },
          }}
          speed={1000}
          autoplay={{
            delay: 3000,
          }}
          watchSlidesProgress={true}
          on={{
            init: () => {
              this.autoplay.stop()
              colorHeader()
            },
          }}
          onSlideChangeTransitionStart={() => colorHeader()}
          className="header-carousel"
          // onSwiper={swiper => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          // style={{
          //   height: `${size.y}px`,
          // }}
        >
          {headerImages.map(headerimage => (
            <SwiperSlide
              key={headerimage.sliderImage.id}
              className={headerimage.css}
            >
              <GatsbyImage
                image={
                  headerimage.sliderImage.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={headerimage.sliderImage.altText}
                css={css`
                  height: 100%;
                  width: 100%;
                `}
              />
            </SwiperSlide>
          ))}
          <div
            className="pagination"
            css={css`
              display: flex;
              position: absolute;
              bottom: 1.5rem;
              padding: ${spacing};
              z-index: 9;
              align-items: center;
              width: 100%;
            `}
          >
            <div
              className="swiper-pagination fade-intro"
              css={css`
                opacity: 0;
                visibilty: hidden;
              `}
            ></div>
            <div
              css={css`
                margin-left: 0.75rem;
                font-size: 1.5rem;
                opacity: 0;
                visibilty: hidden;
              `}
              className="swiper-button-next fade-intro"
            >
              →
            </div>
          </div>
        </Swiper>
      </section>
      <section
        css={css`
          position: relative;
          clear: both;
        `}
      >
        <div
          className="fade"
          css={css`
            max-width: 40rem;
            padding: 6rem clamp(1.2rem, 4vw, 6rem) 3rem;
            font-size: clamp(1.2rem, 3vw, 1.45rem);
            @media ${device.tablet} {
              padding: 9rem clamp(1.2rem, 4vw, 6rem) 4.5rem;
            }
          `}
        >
          <h1
            css={css`
              font-size: clamp(1.2rem, 3vw, 1.45rem);
            `}
          >
            Corrigan Gore
          </h1>
          <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
        </div>
      </section>

      <section className="fade">
        <h2
          id="projects"
          css={css`
            padding-top: 4.5rem;
            padding-left: ${spacingH};
            padding-right: ${spacingH};
          `}
        >
          Featured Projects
        </h2>

        <div
          className="projects-carousel"
          css={css`
            padding-bottom: 4.5rem;
            @media ${device.tablet} {
              padding-bottom: 7.5rem;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              z-index: 9;
              align-items: center;
              padding: 1.5rem 0;
            `}
          >
            <div
              css={css`
                cursor: pointer;
                font-size: 1.25rem;
                margin-left: ${spacingH};
              `}
              className="swiper-prev"
            >
              ←
            </div>
            <div
              css={css`
                width: 70px;
              `}
              className="swiper-projects-pagination"
            ></div>
            <div
              css={css`
                cursor: pointer;
                font-size: 1.25rem;
              `}
              className="swiper-next"
            >
              →
            </div>
          </div>

          <Swiper
            slidesPerView={`auto`}
            loop={true}
            speed={1000}
            initialSlide={0}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
              clickable: true,
              renderPrevButton: className => {
                return '<span class"' + className + '"></span>'
              },
              renderNextButton: className => {
                return '<span class"' + className + '"></span>'
              },
            }}
            breakpoints={{
              540: {
                slidesPerView: 1.15,
              },
              // when window width is >= 640px
              768: {
                slidesPerView: 1.5,
              },

              980: {
                slidesPerView: 2.5,
              },
              // when window width is >= 768px
              1680: {
                slidesPerView: 2.75,
              },
            }}
            pagination={{
              el: ".swiper-projects-pagination",
              type: "custom",
              renderCustom: (swiper, current, total) => {
                // return current + " of " + (total + 1)
                return current + " of " + total
              },
              clickable: true,
            }}
            onSwiper={swiper => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {data.allWpProject.nodes.map(node => (
              <SwiperSlide key={node.slug}>
                <Link to={`/projects/${node.slug}`}>
                  <GatsbyImage
                    image={
                      node.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={node.featuredImage.node.altText}
                  />
                  <div
                    css={css`
                      padding-top: 1.5rem;
                      padding-left: clamp(1.2rem, 4vw, 6rem);
                    `}
                  >
                    {node.title}
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {/* .projects-carousel */}
      </section>

      <section
        id="about"
        className="about"
        css={css`
          display: flex;
          padding: ${spacing};
          margin-bottom: 4.5rem;
          align-items: center;
          flex-direction: column;
          font-size: 1rem;
          @media ${device.tablet} {
            flex-direction: row;
            margin-bottom: 7.5rem;
          }
        `}
      >
        <GatsbyImage
          alt={home.aboutImage.altText}
          image={home.aboutImage.localFile.childImageSharp.gatsbyImageData}
          className="about-image fade"
          css={css`
            width: 100%;
            order: 2;
            @media ${device.tablet} {
              height: calc(100vh - ${spacingH} * 3);
              order: 1;
              width: 50%;
              display: flex;
          `}
        />
        <div
          className="about-text fade"
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto 1.5rem;
            order: 1;
            p:first-of-type {
              font-size: clamp(1.15rem, 2.45621vw, 1.225rem);
            }
            @media ${device.tablet} {
              width: 50%;
              order: 2;
              padding: 0 0 0 ${spacingH};
              justify-content: center;
            }
          `}
        >
          <div
            className="about-copy"
            css={css`
              padding-bottom: ${spacingH};
              @media ${device.tablet} {
                padding-bottom: 0;
                width: 100%;
                max-width: 27.5rem;
                margin: auto;
              }
            `}
          >
            <h2
              css={css`
                align-self: flex-start;
              `}
            >
              About
            </h2>
            <div dangerouslySetInnerHTML={{ __html: home.aboutText }} />
          </div>
        </div>
      </section>

      <section
        id="services"
        className="services"
        css={css`
          display: flex;
          padding: ${spacing};
          margin-bottom: 4.5rem;
          align-items: center;
          flex-direction: column;
          font-size: 1rem;
          @media ${device.tablet} {
            flex-direction: row;
            margin-bottom: 7.5rem;
          }
        `}
      >
        <div
          className="services-text fade"
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: 0 auto 1.5rem;
            p:first-of-type {
              font-size: clamp(1.15rem, 2.45621vw, 1.225rem);
            }
            @media ${device.tablet} {
              width: 50%;
              padding: 0 ${spacingH} 0 0;
              justify-content: center;
              margin: 0 auto;
            }
          `}
        >
          <div
            className="services-copy"
            css={css`
              padding-bottom: ${spacingH};
              @media ${device.tablet} {
                padding-bottom: 0;
                width: 100%;
                max-width: 27.5rem;
                margin: auto;
              }
            `}
          >
            <h2
              css={css`
                align-self: flex-start;
              `}
            >
              Services
            </h2>
            <div dangerouslySetInnerHTML={{ __html: home.servicesText }} />
          </div>
        </div>

        <GatsbyImage
          alt={home.servicesImage.altText}
          image={home.servicesImage.localFile.childImageSharp.gatsbyImageData}
          className="services-image fade"
          css={css`
          width: 100%;
          @media ${device.tablet} {
            height: calc(100vh - ${spacingH} * 3);
            width: 50%;
            display: flex;
        `}
        />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allWpPost(sort: { fields: [date] }) {
      nodes {
        title
        excerpt
        slug
      }
    }
    wpPage(id: { eq: "cG9zdDoy" }) {
      content
      homepage {
        headerimages {
          css
          sliderImage {
            id
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
        aboutImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
        aboutText
        servicesImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
        servicesText
      }
    }
    allWpProject(sort: { fields: [date], order: DESC }) {
      nodes {
        slug
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  placeholder: DOMINANT_COLOR
                  aspectRatio: 1.4
                )
              }
            }
          }
        }
      }
    }
  }
`
